import React from 'react';
import { BooleanInput, ReferenceInput, SelectArrayInput, SelectInput, TextInput } from 'react-admin';

import * as resources from '@/api/resources';
import OrderStatusFilter from '@/components/filters/OrderStatusFilter';
import { QUOTE_AFFILIATES, QUOTE_PARTNERS } from '@/constants/quotePartners';
import { ORDER_TAG_CHOICES } from '@/constants/tags';

const ordersAllFilters = [
  <OrderStatusFilter source="status" alwaysOn />,
  <ReferenceInput source="market_id" reference={resources.MARKETS} perPage={999} sort={{ field: 'name', order: 'ASC' }}>
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    source="partner_id"
    reference={resources.ACCOUNTS}
    filter={{ id: QUOTE_PARTNERS }}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    source="affiliate_id"
    reference={resources.ACCOUNTS}
    filter={{ id: QUOTE_AFFILIATES }}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <BooleanInput source="long_distance" />,
  <TextInput source="confirmation_id" label="Confirm #" alwaysOn autoComplete="off" />,
  <BooleanInput source="transaction_failure_review" label="Transaction Failure" defaultValue="on" />,
  <SelectArrayInput source="tags" choices={ORDER_TAG_CHOICES} />,
];

const ordersAllFilterDefaultValues = {
  status: ['CREATED'],
};

export { ordersAllFilterDefaultValues, ordersAllFilters };
