import React from 'react';

import { CheckboxGroupInput, ReferenceArrayInput, required } from 'react-admin';

import { Box, Typography } from '@mui/material';

import * as resources from '@/api/resources';

const MarketGroupSelection = () => (
  <Box display="flex">
    <Box flex={10}>
      <Typography variant="h6">Individual Markets</Typography>
      <ReferenceArrayInput
        reference={resources.MARKETS}
        source="market_ids"
        perPage={999}
        defaultValue={[]}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <CheckboxGroupInput
          label=""
          sx={{
            '& .MuiFormControlLabel-root': { width: { xs: '25%', lg: '20%' }, my: 1, mx: 0 },
            '& .MuiFormControlLabel-label': { fontSize: '0.9rem' },
          }}
          validate={required()}
          helperText="At least one market is required"
        />
      </ReferenceArrayInput>
    </Box>
  </Box>
);

export default MarketGroupSelection;
