import React, { useContext } from 'react';

import mx from 'mixpanel-browser';
import {
  Form,
  ReferenceInput,
  SelectInput,
  useNotify,
  useRefresh,
  useShowContext,
  useUpdate,
  useRecordContext,
  BooleanInput,
  SelectArrayInput,
} from 'react-admin';
import { useWatch } from 'react-hook-form';

import { Box, Button, Divider, Typography, Grid } from '@mui/material';

import * as resources from '@/api/resources';
import { OrderActionContext } from '@/providers/OrderAction';
import { QUOTE_AFFILIATES } from '@/constants/quotePartners';
import { RESCHEDULE_REASONS_BY_TYPE, CANCEL_REASONS_BY_TYPE } from '@/constants/quotes';
import { ORDER_RESCHEDULE_TYPE, ORDER_CANCEL_TYPE } from '@/constants/order';
import { ORDER_TAG_CHOICES } from '@/constants/tags';

const UpdateOrderForm = () => {
  const { setAction } = useContext(OrderActionContext);

  const record = useRecordContext();
  const rescheduleType = useWatch({ name: 'reschedule_type' });
  const cancelType = useWatch({ name: 'cancel_type' });

  if (!record) return null;
  return (
    <>
      <Box p={2}>
        <Box pb={2}>
          <Typography>
            If <strong>Transaction Failure</strong> is on, order will be auto cancelled per normal rules, set to off for
            exceptions.
          </Typography>
        </Box>
        <BooleanInput source="transaction_failure_review" label="Transaction Failure" />
        <ReferenceInput
          reference={resources.ACCOUNTS}
          source="affiliate_id"
          filter={{ id: QUOTE_AFFILIATES }}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </Box>
      {record?.reschedule_type !== null ? (
        <Box p={2}>
          <Grid container>
            <Grid item xs={12}>
              <SelectInput
                label="reschedule Type"
                source="reschedule_type"
                choices={ORDER_RESCHEDULE_TYPE}
                defaultValue={record?.reschedule_type}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                label="reschedule Reason"
                source="reschedule_reason"
                choices={RESCHEDULE_REASONS_BY_TYPE[rescheduleType]?.map((item) => ({ id: item, name: item } ?? []))}
                defaultValue={record?.reschedule_reason}
              />
            </Grid>
          </Grid>
        </Box>
      ) : null}

      {record?.cancel_type !== null ? (
        <Box p={2}>
          <Grid container>
            <Grid item xs={12}>
              <SelectInput
                label="Cancel Type"
                source="cancel_type"
                choices={ORDER_CANCEL_TYPE}
                defaultValue={record?.cancel_type}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                label="Cancel Reason"
                source="cancel_reason"
                choices={CANCEL_REASONS_BY_TYPE[cancelType]?.map((item) => ({ id: item, name: item } ?? []))}
                defaultValue={record?.cancel_reason}
              />
            </Grid>
          </Grid>
        </Box>
      ) : null}

      <SelectArrayInput source="tags" choices={ORDER_TAG_CHOICES} />

      <Divider />
      <Box pt={2} px={2} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={() => setAction(null)}>
          Back
        </Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </>
  );
};

const UpdateOrder = () => {
  const { setAction } = useContext(OrderActionContext);
  const { record } = useShowContext();
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  const { id: orderId, confirmation_id: confirmationId } = record;

  const onError = ({ message, status }) => {
    notify(`Order Update failed - ${status} - ${message}`, {
      type: 'error',
    });
  };

  const onSuccess = () => {
    mx.track(`Order Management - Order Updated`, {
      resource: resources.ORDERS,
      resourceId: orderId,
      confirmationId,
    });

    notify('Order Updated', {
      type: 'success',
    });
    refresh();
    setAction(null);
  };

  const handleSubmit = (data) => {
    update(
      resources.ORDERS,
      {
        id: orderId,
        data,
        previousData: { ...record, cancel_type: null, cancel_reason: null },
      },
      { onError, onSuccess },
    );
  };

  return (
    <Box px={2} py={4} width={475}>
      <Typography variant="h4" component={Box} pb={4} pl={2}>
        Update Order
      </Typography>
      <Divider />
      <Box>
        <Form onSubmit={handleSubmit}>
          <UpdateOrderForm />
        </Form>
      </Box>
    </Box>
  );
};

export default UpdateOrder;
